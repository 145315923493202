import React from 'react'
import LayoutWrapper from './LayoutWrapper'

const DefaultLayout = ({ children }) => {
  return (
    <LayoutWrapper>
      <div className="flex flex-col justify-center items-center w-full h-full bg-grey-300 hero-background">
        <div className="shadow-xl rounded-lg m-8 p-4 bg-white">{children}</div>
      </div>
    </LayoutWrapper>
  )
}

export default DefaultLayout
